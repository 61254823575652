<template>
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="d-flex pt-3">
        <el-date-picker
          v-model="search.date_range"
          type="daterange"
          size="small"
          :picker-options="date_picker_detail_options"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date">
        </el-date-picker> &nbsp;
        <!-- <el-button :loading="loading.table" @click="search.page = 1; getList();" size="small" class="btn-primary" icon="el-icon-search">Search</el-button> -->
      </div>
      <div class="row pt-3">
        <div class="col-lg-3">
          <el-select v-model="search.search_by" size="small" class="w-100">
            <el-option v-for="(item, i) in search_options" :key="i" :value="item.value" :label="item.label" />
          </el-select>
        </div>
        <div class="col-lg-3">
          <el-input size="small" clearable @clear="search.page = 1; currentPage = 1; getList();" v-model="search.search_keyword" placeholder="Keyword you want to search"></el-input>
        </div>
        <div class="col-lg-2">
          <el-select v-model="search.status_filter" size="small" class="w-100">
            <el-option v-for="(item, i) in status_list" :key="i" :value="item.value" :label="item.label" />
          </el-select>
        </div>
        <div class="col-lg-3">
          <el-button :loading="loading.table" @click="search.page = 1; currentPage = 1; getList();" size="small" class="btn-default" icon="el-icon-search">Search</el-button>
          <el-button @click="handlerClickDownloadBtn" type="primary" size="mini" icon="el-icon-download">Download</el-button>
        </div>
      </div>
      <div class="table-responsive pt-3">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Template Name</th>
              <th>Channel</th>
              <th>Recipient</th>
              <th>Status</th>
              <th>Sent At</th>
              <th>Delivered At</th>
              <th>Read At</th>
              <th>Failed At</th>
              <th>Timestamp</th>
              <th>Message ID</th>
              <th>
                User Response
                <el-tooltip class="item" effect="dark" content="User response when send a template with Reply Buttons" placement="top">
                  <font-awesome icon="question-circle"/>
                </el-tooltip>
              </th>
            </tr>
          </thead>
          <tbody v-if="batchList.rows.length > 0" v-loading="loading.table">
            <tr v-for="(item) in batchList.rows" :key="item.id">
              <td>{{ item.template_name }}</td>
              <td><i :class="[activeBatch.channel_icon, activeBatch.channel_color]"/> {{activeBatch.channel_name_str}}</td>
              <td>{{ item.raw_recipient_contact }}</td>
              <td>
                <el-popover
                  placement="right"
                  width="400"
                  v-if="item.status == 'failed' && item.failed_error_title"
                  trigger="hover">
                  {{ item.failed_error_title }}
                  <span @click="visible = !visible" slot="reference">
                    <i :class="[item.status_icon, item.status_color]"/> {{ item.status_str }} <font-awesome :icon="['far', 'question-circle']"/>
                  </span>
                </el-popover>
                <span v-else>
                  <i :class="[item.status_icon, item.status_color]"/> {{ item.status_str }}
                </span>
              </td>
              <td>{{ item.sent_at_str }}</td>
              <td>{{ item.delivered_at_str }}</td>
              <td>{{ item.read_at_str }}</td>
              <td>{{ item.failed_at_str }}</td>
              <td>{{ item.created_at_str }}</td>
              <td>{{ item.msg_id }}</td>
              <td>
                <span v-if="!item.user_responses.length">-</span>
                <span v-else v-for="item1 in item.user_responses" :key="item1.msg_id">
                  <el-tag size="small">{{ item1.body }}</el-tag>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty v-if="batchList.rows.length == 0" description="No data"></el-empty>
      </div>
      <el-pagination @current-change="handlerPageChange" v-model="search.page" :per-page="per_page"/>
    </div>

    <b-modal centered v-model="show_modal.download" :title="this.$t('general.confirmation')" hide-footer @hide="handlerHideModalDownload">
      <p class="mb-2 text-center">
        {{ this.$t('report.confirm_download') }}
        <br>
        You can choose delimiter for your export file.
      </p>
      <b-form>
        <b-form-group class="mb-4 text-center">
          <el-select v-model="search.delimiter" size="small" class="w-30">
            <el-option value=";" label="Delimiter ;" />
            <el-option value="," label="Delimiter ," />
          </el-select>
        </b-form-group>
      </b-form>
      <div class="mt-2 text-center">
        <b-button size="sm" type="submit" @click="confirmDownload" variant="primary" class="mr-2">{{ $t('general.yes') }}</b-button>
        <b-button size="sm" type="reset" @click="handlerHideModalDownload" variant="secondary">{{ $t('general.no') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { upperFirst, forEach } from 'lodash';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import reportApi from '@/api/reports';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  beforeCreate() {
    this.moment = moment;
    this.humanizeDuration = humanizeDuration;
  },
  name: 'BroadcastReports',
  metaInfo: {
    title: 'Broadcast Reports',
  },
  data() {
    return {
      upperFirst,
      search: {
        page: 1,
        date_range: [
          moment().subtract(7, 'days'),
          moment(),
        ],
        // date_range: [
        //   this.$route.query?.start_date || moment().subtract(7, 'days'),
        //   this.$route.query?.end_date || moment(),
        // ],
        search_by: 'phone_number',
        search_keyword: '',
        status_filter: '',
        delimiter: ';',
      },
      date_picker_detail_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 180 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const start = moment().subtract(6, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      loading: {
        table: false,
      },
      page: 1,
      per_page: 20,
      batchList: {
        count: 0,
        rows: [],
        status_count: {},
      },
      search_options: [
        {
          value: 'phone_number',
          label: 'Phone Number',
        },
        {
          value: 'msg_id',
          label: 'Message ID',
        },
      ],
      status_list: [
        {
          label: 'All',
          key: 'total',
          value: '',
        },
        {
          label: 'Sent',
          value: 'sent',
          key: 'sent',
        },
        {
          label: 'Delivered',
          value: 'delivered',
          key: 'delivered',
        },
        {
          label: 'Read',
          value: 'read',
          key: 'read',
        },
        {
          label: 'Failed',
          value: 'failed',
          key: 'failed',
        },
        {
          label: 'Invalid',
          value: 'invalid',
          key: 'invalid',
        },
      ],
      show_modal: {
        download: false,
      },
    };
  },
  computed: {
    activeBatch() {
      return this.$store.state.report.activeBatch;
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
    company_id() {
      return this.$store.state.backOffice.activeCompany;
    },
    token() {
      return this.$store.state.backOffice.token;
    },
  },
  mounted() {
  },
  methods: {
    async getList() {
      this.loading.table = true;
      const response = await reportApi.batchDetail({
        workspace_id: this.activeWorkspace._id,
        batch_id: 'details',
        start_date: moment(this.search.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.date_range[1]).format('YYYY-MM-DD'),
        company_id: this.company_id,
        token: this.token,
        query: this.search,
      });
      await popupErrorMessages(response);
      this.batchList = response.data;
      if (response.data.rows) {
        this.batchList.status_count.total = 0;
        forEach(this.batchList.status_count, (v, k) => {
          if (k !== 'total') this.batchList.status_count.total += v;
        });
        this.batchList.rows = response.data.rows.map((v) => {
          v.status_str = upperFirst(v.status);
          v.status_color = '';
          v.sent_at_str = v.sent_at ? moment(v.sent_at).format('DD MMM YYYY, HH:mm') : '-';
          v.delivered_at_str = v.delivered_at ? moment(v.delivered_at).format('DD MMM YYYY, HH:mm') : '-';
          v.read_at_str = v.read_at ? moment(v.read_at).format('DD MMM YYYY, HH:mm') : '-';
          v.failed_at_str = v.failed_at ? moment(v.failed_at).format('DD MMM YYYY, HH:mm') : '-';
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          if (v.status === 'pending') {
            v.sent_at_str = '-';
          }
          if (v.status === 'sent') {
            v.status_icon = 'mdi mdi-check';
            if (v.sent_at_str === '-') {
              v.sent_at_str = v.created_at_str;
            }
          } else if (['delivered', 'read'].includes(v.status)) {
            v.status_icon = 'mdi mdi-check-all';
            if (v.delivered_at_str === '-') {
              v.delivered_at_str = v.sent_at_str !== '-' ? v.sent_at_str : v.created_at_str;
            }
            if (v.status === 'read') {
              if (v.read_at_str === '-') {
                v.read_at_str = v.delivered_at_str;
              }
              v.status_color = 'text-read';
            }
          } else if (v.status === 'failed' || v.status === 'invalid') {
            v.sent_at_str = '-';
            v.status_icon = 'mdi mdi-close-circle-outline';
            v.status_color = 'text-danger';
          }

          return v;
        });
      }
      this.loading.table = false;
    },
    handlerClickDownloadBtn() {
      this.show_modal.download = true;
      this.search.delimiter = ';';
    },
    handlerHideModalDownload() {
      this.show_modal.download = false;
    },
    async confirmDownload() {
      this.handlerHideModalDownload();
      this.loading.table = true;
      const response = await reportApi.batchDetailDownload({
        workspace_id: this.activeWorkspace._id,
        company_id: this.company_id,
        token: this.token,
        batch_id: 'details',
        start_date: moment(this.search.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.date_range[1]).format('YYYY-MM-DD'),
        query: this.search,
      }).catch(() => {});
      this.loading.table = false;
      await popupErrorMessages(response);
      this.$message({
        title: this.$t('general.success'),
        type: 'success',
        message: this.$t('general.waiting_download'),
        duration: 30 * 1000,
        showClose: true,
      });
    },
    handlerPageChange(page) {
      this.currentPage = page;
      this.search.page = page;
    },
  },
};
</script>
<style>
.text-read {
  color: #249393;
  font-weight:bold;
}
</style>
