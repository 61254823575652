<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <ul class="nav nav-tabs mt-3" ref="tabStatusConvo" id="tabStatusConvo" role="tablist"
            @click="tabSettingClicked">
              <li class="nav-item">
                <a class="nav-link active" ref="broadcast-batch-tab" id="broadcast-batch-tab" data-toggle="tab" href="#broadcast-batch" role="tab" aria-controls="broadcast-batch" aria-selected="false">
                  <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                    <p id="broadcast-batch-title">By Batch</p>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" ref="broadcast-detail-tab" id="broadcast-detail-tab" data-toggle="tab" href="#broadcast-detail" role="tab" aria-controls="broadcast-detail" aria-selected="false">
                  <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                    <p id="broadcast-detail-title">By Detail</p>
                  </div>
                </a>
              </li>
            </ul>
            <div class="tab-content mt-3" v-loading="loading.setting" style="min-height: 400px; height: auto;">
              <!-- <div class="tab-pane fade show active" ref="minibot-content" id="minibot" role="tabpanel" aria-labelledby="minibot">
                <minibot
                  ref="minibot"
                  :workspace_id="activeWorkspace._id"/>
              </div>
              <div class="tab-pane fade" ref="work-hour-content" id="work-hour" role="tabpanel" aria-labelledby="work-hour">
                <work-hour
                  ref="work-hour"
                  :workspace_id="activeWorkspace._id"/>
              </div> -->
              <div class="tab-pane fade show active" ref="broadcast-batch-content" id="broadcast-batch" role="tabpanel" aria-labelledby="broadcast-batch">
                <!-- <auto-reminder
                  ref="auto-reminder"
                  :workspace_id="activeWorkspace._id"/> -->
                <div class="d-flex pt-3">
                  <el-date-picker
                    v-model="search.date_range"
                    type="daterange"
                    size="small"
                    :picker-options="date_picker_options"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker> &nbsp;
                  <!-- <el-select v-model="dateFilterType" size="small">
                    <el-option v-for="(item, i) in dateFilterTypeOptions" :key="i" :value="item.value" :label="item.text" />
                  </el-select> &nbsp; -->
                  <el-button :loading="loading.table" @click="page = 1; getList();" size="small" class="btn-primary" icon="el-icon-search">Search</el-button>
                  <!-- <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-primary" icon="el-icon-position">Send to Email</el-button> -->
                </div>
                <hr/>
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Batch Name</th>
                        <th>Send Time</th>
                        <th>Channel</th>
                        <th>Template Name</th>
                        <th>Total Recipient</th>
                        <!-- <th>Success Count</th>
                        <th>Failed Count</th>
                        <th>Progress</th> -->
                        <!-- <th>Amount</th> -->
                        <!-- <th>#</th> -->
                      </tr>
                    </thead>
                    <tbody v-if="batchList.count > 0" v-loading="loading.table">
                      <tr v-for="(item) in batchList.rows" :key="item.id" class="cursor-pointer">
                        <td @click="showDetailBatch(item)">{{ item.created_at_str }}</td>
                        <td @click="showDetailBatch(item)">{{ item.batch_name }}</td>
                        <td @click="showDetailBatch(item)">{{ item.send_time == 'now' ? item.created_at_str : item.scheduled_at_str }}</td>
                        <td @click="showDetailBatch(item)"><i :class="[item.channel_icon, item.channel_color]"/> {{item.channel_name_str}}</td>
                        <td @click="showDetailBatch(item)">{{ item.template_name }}</td>
                        <td @click="showDetailBatch(item)">{{ item.total_recipients }}</td>
                        <!-- <td @click="showDetailBatch(item)">{{ item.success_count }}</td>
                        <td @click="showDetailBatch(item)">{{ item.failed_count }}</td>
                        <td @click="showDetailBatch(item)">{{ item.progress_str }}</td> -->
                        <!-- <td @click="showDetailBatch(item)">{{ item.total_amount_str }}</td> -->
                        <!-- <td>
                          <el-button @click="confirmDownload(item.id)" size="small" class="btn-primary" icon="el-icon-download">Download</el-button>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                  <el-empty v-if="batchList.count == 0" description="No data"></el-empty>
                </div>
                <b-pagination v-if="batchList.count > per_page" v-model="currentPage" :total-rows="batchList.count" :per-page="per_page"/>
              </div>
              <div class="tab-pane fade" ref="broadcast-detail-content" id="broadcast-detail" role="tabpanel" aria-labelledby="broadcast-detail">
                <!-- <el-empty description="Coming soon"></el-empty> -->
                <broadcast-detail
                  ref="broadcast-detail"
                  :workspace_id="activeWorkspace._id"/>
                <!-- <div class="d-flex pt-3">
                  <el-date-picker
                    v-model="search.date_range"
                    type="daterange"
                    size="small"
                    :picker-options="date_picker_options"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker> &nbsp;
                  <el-button :loading="loading.table" @click="page = 1; getList();" size="small" class="btn-primary" icon="el-icon-search">Search</el-button>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-3">
                    <el-select v-model="search.search_by" size="small" class="w-100">
                      <el-option v-for="(item, i) in search_options" :key="i" :value="item.value" :label="item.label" />
                    </el-select>
                  </div>
                  <div class="col-lg-3">
                    <el-input size="small" clearable @clear="search.page = 1; getList();" v-model="search.search_keyword" placeholder="Keyword you want to search"></el-input>
                  </div>
                  <div class="col-lg-2">
                    <el-select v-model="search.status_filter" size="small" class="w-100">
                      <el-option v-for="(item, i) in status_list" :key="i" :value="item.value" :label="item.label" />
                    </el-select>
                  </div>
                  <div class="col-lg-3">
                    <el-button :loading="loading.table" @click="search.page = 1; getList();" size="small" class="btn-default" icon="el-icon-search">Search</el-button>
                    <el-button @click="confirmDownload" type="primary" size="mini" icon="el-icon-download">Download</el-button>
                  </div>
                </div>
                <div class="table-responsive pt-3">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Template Name</th>
                        <th>Channel</th>
                        <th>Recipient</th>
                        <th>Status</th>
                        <th>Timestamp</th>
                        <th>Message ID</th>
                        <th>
                          User Response
                          <el-tooltip class="item" effect="dark" content="User response when send a template with Reply Buttons" placement="top">
                            <font-awesome icon="question-circle"/>
                          </el-tooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="batchList.count > 0" v-loading="loading.table">
                      <tr v-for="(item) in batchList.rows" :key="item.id">
                        <td>{{ item.template_name }}</td>
                        <td><i :class="[activeBatch.channel_icon, activeBatch.channel_color]"/> {{activeBatch.channel_name_str}}</td>
                        <td>{{ item.raw_recipient_contact }}</td>
                        <td>
                          <el-popover
                            placement="right"
                            width="400"
                            v-if="item.status == 'failed' && item.failed_error_title"
                            trigger="hover">
                            {{ item.failed_error_title }}
                            <span @click="visible = !visible" slot="reference">
                              <i :class="[item.status_icon, item.status_color]"/> {{ item.status_str }} <font-awesome :icon="['far', 'question-circle']"/>
                            </span>
                          </el-popover>
                          <span v-else>
                            <i :class="[item.status_icon, item.status_color]"/> {{ item.status_str }}
                          </span>
                        </td>
                        <td>{{ item.created_at_str }}</td>
                        <td>{{ item.msg_id }}</td>
                        <td>
                          <span v-if="!item.user_responses.length">-</span>
                          <span v-else v-for="item1 in item.user_responses" :key="item1.msg_id">
                            <el-tag size="small">{{ item1.body }}</el-tag>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <el-empty v-if="batchList.count == 0" description="No data"></el-empty>
                </div>
                <b-pagination v-if="batchList.count > per_page" v-model="currentPage" :total-rows="batchList.count" :per-page="per_page"/> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isEqual } from 'lodash';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import BroadcastDetail from '@/ui/components/report-broadcast/TabBatchDetail.vue';
import reportApi from '../../../api/reports';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  components: { BroadcastDetail },
  beforeCreate() {
    this.moment = moment;
    this.humanizeDuration = humanizeDuration;
  },
  name: 'BroadcastReports',
  metaInfo: {
    title: 'Broadcast Reports',
  },
  data() {
    return {
      search: {
        date_range: [
          this.$route.query?.start_date || moment().subtract(7, 'days'),
          this.$route.query?.end_date || moment(),
        ],
      },
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 180 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const start = moment().subtract(6, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      loading: {
        table: false,
      },
      page: this.$route.query.page || 1,
      per_page: 20,
      batchList: {
        count: 0,
        rows: [],
      },
      tab_title: {
        'broadcast-batch': 'Broadcast - Batch Date',
        'broadcast-detail': 'Broadcast - Detail Batch',
      },
      active_tab: 'broadcast-batch',
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    company_id() {
      return this.$store.state.backOffice.activeCompany;
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getList();
      },
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
  },
  methods: {
    async getList() {
      this.loading.table = true;
      const options = {
        page: this.page.toString(),
        per_page: this.per_page.toString(),
        start_date: moment(this.search.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.date_range[1]).format('YYYY-MM-DD'),
      };
      const response = await reportApi.batchList({
        workspace_id: this.activeWorkspace._id,
        company_id: this.company_id,
        ...options,
      });
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      await popupErrorMessages(response);
      this.batchList = response.data;
      if (response.data.rows) {
        this.batchList.rows = response.data.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          if (v.scheduled_at) {
            v.scheduled_at_str = moment(v.scheduled_at).format('DD MMM YYYY, HH:mm');
          }
          v.progress = (v.waiting_count / v.total_recipients) * 100;
          v.channel_icon = `mdi mdi-${v.channel_name}`;
          if (v.channel_name === 'whatsapp') {
            v.channel_name_str = 'WhatsApp';
            v.channel_color = 'text-success';
          }
          v.progress_str = `${v.complete_count} of ${v.total_recipients}`;
          v.total_amount_str = `Rp. ${v.total_amount ? v.total_amount.toLocaleString() : 0}`;
          return v;
        });
      }
      this.loading.table = false;
    },
    async showDetailBatch(item) {
      await this.$store.dispatch('report/setActiveBatch', item);
      this.$router.push({
        name: 'Broadcast Reports Detail',
        params: {
          id: item.id,
        },
      });
    },
    confirmDownload(batch_id) {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.loading.table = true;
            const response = await reportApi.batchDetailDownload({
              workspace_id: this.activeWorkspace._id,
              batch_id,
            }).catch(() => {});
            this.loading.table = false;
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 30 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
    tabSettingClicked(e) {
      const id = e.target.id.replace(/-title|-tab/ig, '');
      console.log(id);
      if (['broadcast-detail'].includes(id)) {
        this.$refs[id].getList();
      } else {
        this.getList();
      }
      this.active_tab = id;
    },
  },
};
</script>
